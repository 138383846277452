html, body{
	min-height:100%;
	position: relative;
	margin:0 !important;
	font-weight: 300;
	font-size: @font-size-base;
}
a, .btn, button, .myanimated{
	.transition(all 300ms ease);
}
.slowanimated{
	.transition(all 2000ms cubic-bezier(0.250, 0.100, 0.250, 1.000));
}

.bezieranimated{
	.transition(all 300ms cubic-bezier(0.420, 0.000, 0.580, 1.000));
} 
h1{
    margin:0 0 1em 0;
    padding:0;
    color:@white;
    &.page-header{
    	font-weight: 600;
    	line-height: 1em;
    	vertical-align: baseline;
    	&:before{
    		width:0.6em;
    		height:0.6em;
    		background:@theme-color-1;
    		margin:0 1rem 0 0;
    		display: inline-block;
    		vertical-align: baseline;
    		content: '';
    	}
    }
}
h2, h3, h4, h5{
    margin:1em 0 0.5em 0;
    padding:0;
    color:@white;
    
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span{
	color:@theme-color-1;
}
b, strong{
	font-weight:600;
}
.block-title{
	margin:0 0 1em 0;
	font-size:@font-size-h3;
	text-align: center;
}
#toolbar-administration{
	font-size:12px;
	display:none;
	@media @normal{
		display:block;
	}
}
#admin-menu{
	font-size:12px;
	display:none;
	@media @tablet{
		display:block;
	}
}
body.admin-menu{
	margin-top:0!important;
	@media @tablet{
		margin-top:29px!important;
	}
}
body.noscroll{
	position: fixed;
	left:0;
	top:0;
	width:100%;
	@media @normal{
		position: static;
	}
}
.btn{
	padding:0.5em 1em;
	background: @gray-lighter;
	border:0;
	font-weight: 400;
	color:@text-color;
	.border-radius(0);
	line-height: 1em;
	&:hover{
		background:@gray-light;
		color:@text-color;
	}
}
	.bluebtn{
		background: @theme-color-2;
		color:@white;
		&:hover{
			background:@theme-color-2-hover;
			color:@white;
		}
	}
	.redbtn{
		background: @theme-color-1;
		color:@white;
		&:hover{
			background:darken(@theme-color-1, 10%);
			color:@white;
		}
	}
	.yellowbtn{
		background: @theme-color-3;
		color:@white;
		&:hover{
			background:darken(@theme-color-3, 10%);
			color:@white;
		}
	}
	.bigbtn{
		padding:0.75em 2em;
		font-size: @font-size-large;
	}
.blue{
	color:@theme-color-1;
}
.underlined{
	position: relative;
	padding-bottom:1rem;
	&:after{
		height:1px;
		width:40px;
		position:absolute;
		left:50%;
		bottom:0;
		margin-left:-20px;
		content:'';
		display: block;
		background: @theme-color-2;
	}
}
.widecontainer{
	@media @xwide{
		width:90%;
	}
}
.field .field-label {
    font-weight: 600;
}