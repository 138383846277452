/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
@icon-font-path: '../bootstrap/fonts/';


@gray-base:              #000;
@gray-dark:              #222222;
@gray-mid-dark:          #363636;
@gray:                   #555555;
@gray-light:             @grey-400;
@gray-more-light:        #b7b7b7;
@gray-lighter:           @grey-100;
@gray-lightest:          @grey-50;
@gray-trans: rgba(0, 0, 0, 0.25);
@white: #fff;

@body-bg: @gray-dark;

/*See all colors in _colors.less*/
@theme-color-1: #cb2a2a; /* Red*/
@theme-color-2: #275273; /*Blue*/
@theme-color-2-hover: #276ca1; /*Blue - light*/
@theme-color-3: #ffba00; /* Yellow*/

@text-color:            @white;
@link-color:            @gray-lightest;
@link-hover-color:      @theme-color-3;

@font-family-sans-serif: 'Lato', 'Helvetica', sans-serif;
@font-family-awesome:  'FontAwesome';

@font-family-base:        @font-family-sans-serif;

@font-size-base:          12pt;
@font-size-large:         13.5pt;
@font-size-small:         10pt;
@font-size-h1:            22pt;
@font-size-h2:            19pt;
@font-size-h3:            16pt;
@font-size-h4:            15pt; 
@font-size-h5:            14pt;
@line-height-base:        1.6;


@pagination-color:                     @theme-color-1;
@pagination-bg:                        @white;
@pagination-border:                    @gray-lighter;

@pagination-hover-color:               @theme-color-1;
@pagination-hover-bg:                  @gray-lighter;
@pagination-hover-border:              @gray-lighter;

@pagination-active-color:              @white;
@pagination-active-bg:                 @theme-color-1;
@pagination-active-border:             @theme-color-1;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               @white;
@pagination-disabled-border:           @gray-lighter;

@grid-gutter-width:         2rem;
@screen-xlg:                  1400px;
@screen-xlg-min:              @screen-xlg;
@screen-xlg-desktop:          @screen-xlg-min;
@screen-xlg-max:               (@screen-xlg-min - 1);
@container-xlarge-desktop:      (1380px + @grid-gutter-width*2);
@container-xlg:                 @container-xlarge-desktop;