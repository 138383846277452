// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

// Base-theme overrides.
@import 'variable-overrides';

// Additional mixins.
@import 'user-mixins';

// Material design colors
@import '_colors';

// Styles for used elements
@import 'elements';

body {
  background-image: url(../images/fern.svg);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 0 100px;
}
.overlay {
  display: none;
  &.shadow {
    top: -56px;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    @media @normal {
      display: none;
    }
  }
  &.shadowwide {
    top: -56px;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}
.header {
  color: @white;
  display: block;
  position: fixed;
  width: 100%;
  min-height: 100%;
  z-index: 1001;
  left: -100%;
  top: 0;
  padding: 0;
  .box-shadow(0 0 35px rgba(0, 0, 0, 0.75));
  .myanimated;
  .header_wrapper {
    .transition(all 300ms ease);
    position: absolute;
    width: calc(~'100% - 40px');
    height: 100%;
    left: -100%;
    background: @gray-dark;
    overflow-y: scroll;
    z-index: 10000;
  }
  .close {
    .transition(all 300ms ease);
    color: @white;
    font-size: 16pt;
    width: 40px;
    height: 40px;
    background: @theme-color-1;
    position: absolute;
    top: 0;
    right: 100%;
    padding: 4px 10px;
    z-index: 9999;
    .box-shadow(inset 0px 0px 5px rgba(0, 0, 0, 0.15));
    cursor: pointer;
    opacity: 1;
    font-weight: normal;
    padding-top: 0.35em;
  }
  .clickme {
    position: absolute;
    z-index: 9998;
    height: 100%;
    width: 100%;
  }
  &.open {
    left: 0;
    top: 0;
    .header_wrapper {
      left: 0;
    }
    .close {
      right: 0;
    }
  }
  a {
    color: @gray-light;
  }
  .container {
    width: 100%;
  }
  .topmenu {
    .myanimated;
    .logo {
      padding: 1em 0;
      margin: 0.5em 0 2em 0;
      border-bottom: 1px solid @gray;
      text-align: center;
      .myanimated;
      svg {
        max-width: 80px;
        width: 100%;
        height: auto;
        .myanimated;
      }
    }
    .mainmenu {
      padding-top: 1px;
      padding-bottom: 1px;
      .nav {
        padding: 0;
        margin: 0;
        li {
          display: block;
          a {
            display: block;
            margin: 0;
            padding: 0.5em 0;
            background: none;
            text-transform: uppercase;
            border-bottom: 1px solid darken(@theme-color-1, 5%);
            color: @white;
            font-weight: 600;
            &:hover {
              text-decoration: underline;
              color: @white;
            }
            &.active {
              color: @white;
            }
            &.fa {
              font-size: 0;
              font-weight: normal;
              &:before {
                font-size: @font-size-h2;
              }
              &:hover {
                text-decoration: none;
              }
            }
          }
          &.dropdown {
            .dropdown-menu {
              display: block;
              border: none;
              .border-radius(0);
              padding: 0.25em 1em;
              margin: -1px 0 0 0;
              position: relative;
              float: none;
              .box-shadow(none);
              background: none;
              li {
                a {
                  padding: 0.25em 0;
                  border-bottom: none;
                  color: @white;
                  font-weight: 400;
                  text-transform: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media @normal {
    z-index: 100;
    position: fixed;
    width: 100%;
    min-height: 0;
    left: 0;
    color: @gray-base;
    .box-shadow(none);
    .header_wrapper {
      position: relative;
      width: 100%;
      height: auto;
      left: 0;
      .clearfix;
      overflow-y: visible;
      background: none;
    }
    &:after {
      display: none;
    }
    .close {
      display: none;
    }
    .clickme {
      display: none;
    }
    .container {
      width: 970px;
      position: relative;
    }
    .topmenu {
      padding: 1em 2em;
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

      .clearfix;
      .logo {
        margin: 5px 0 0 0;
        padding: 0;
        border-top: none;
        border-bottom: none;
        text-align: left;
        svg {
          width: 100%;
          max-width: 80px;
          height: auto;
        }
      }
      .mainmenu {
        padding: 2rem 0;
        .nav {
          text-align: right;
          li {
            display: inline-block;
            a {
              font-size: @font-size-base;
              display: block;
              margin: 0;
              padding: 0 0.35em;
              background: none;
              text-transform: uppercase;
              border-bottom: none;
              &:hover {
                text-decoration: underline;
                color: @white;
              }
              &.active,
              &.highlite {
                color: @theme-color-1;
              }
            }
            &.dropdown {
              .dropdown-menu {
                position: absolute;
                float: left;
                display: none;
                .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
                padding: 0.5em;
                background: #cb2a2a;
                li {
                  display: block;
                  a {
                    padding: 0.25em 0;
                    border-bottom: none;
                    color: @white;
                    &.active {
                      background: none;
                      color: @white;
                    }
                    &:hover {
                      color: @white;
                    }
                  }
                }
              }
              &:hover {
                .dropdown-menu {
                  display: block;
                }
              }
            }
          }
        }
        &.usermenu {
          .nav {
            text-align: right;
          }
        }
      }
    }
    &.collapsed {
      /*	background:@gray-base;*/

      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

      .topmenu {
        /*padding:0.5em 3em;*/
        .logo {
          svg {
            max-width: 95px;
          }
        }
        /*.mainmenu{
					padding:1rem 0;
				}*/
      }
    }
  }
  @media @wide {
    .topmenu {
      padding: 1em 3em;
      .logo {
        svg {
          max-width: 95px;
        }
      }
      .mainmenu {
        .nav {
          li {
            a {
              font-size: @font-size-base;
              padding: 0 0.5em;
            }
          }
        }
      }
    }
    &.collapsed {
      .topmenu {
        .mainmenu {
          .nav {
            li {
              a {
                padding: 0em 0.5em;
              }
            }
          }
        }
      }
    }
  }
}

.mobile_header {
  display: block;
  padding: 0.5em 1rem;
  background: @gray-dark;
  color: @theme-color-1;
  vertical-align: middle;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  .clearfix;
  @media @normal {
    display: none;
  }
  .menu-btn {
    display: block;
    font-size: 18pt;
    font-weight: normal;
    padding: 0.25em;
    .border-radius(3px);
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    float: right;
  }
  .header-title {
    display: block;
    float: left;
    font-size: @font-size-large;
    line-height: 1;
    font-weight: 300;
    padding: 0.4em 0 0 0;
    svg {
      max-height: 30px;
      max-width: 80px;
    }
  }
}
.hp_img {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    background: url(../images/overlay-pattern.png);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    visibility: visible;
    display: block;
  }
  .region-hp-img {
    .flex-container(row, wrap, flex-start, flex-start, flex-start);
    .hp-images-item {
      background-color: #000;
      position: relative;

      .slowanimated;
      .flex-element(0, 1, 1, 100%);

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: none;
        box-shadow: inset 0px 100px 100px -50px rgba(0, 0, 0, 0.5),
          inset 0px -100px 100px -50px rgba(0, 0, 0, 0.5);
        display: block;
        left: 0;
        top: 0;
      }

      img {
        width: 100%;
        height: 200px;
        .object-fit(cover);
      }
    }
    @media @tablet {
      .flex-container(row, nowrap, flex-start, stretch, stretch);
      .hp-images-item {
        .flex-element(0, 1, 1, 20%);
        img {
          height: 50vh;
        }
        &:hover {
          .flex-element(0, 1, 1, 50%);
        }
      }
    }
    @media @normal {
      .hp-images-item {
        img {
          height: 100vh;
        }
        &:before {
          box-shadow: inset 0px 200px 200px -100px rgba(0, 0, 0, 0.66),
            inset 0px -200px 200px -100px rgba(0, 0, 0, 0.66);
        }
      }
    }
    @media @xwide {
      .hp-images-item {
        &:hover {
          .flex-element(0, 1, 1, 60%);
        }

        &.col-60 {
          flex: 60% !important;
          &:hover {
            flex: 60% !important;
          }
          img {
            transform: scale(0.7);
          }
        }

        &.col-20 {
          flex: 20% !important;
          &:hover {
            flex: 20% !important;
          }
        }
      }
    }
  }
}

.footer {
  padding: 2em 0;
  margin: 0;
  background: @gray-base;
  color: @white;
  border: none;
  .container {
    background: none;
  }
  .copyright {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
  .view-partners {
    margin-bottom: 2rem;
    .view-content {
      .flex-container(row, wrap, center, center, flex-start);
      .views-row {
        .flex-element(0, 0, 0, auto);
        text-align: center;
        padding: 0 @grid-gutter-width;
        img {
          display: inline-block;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
          .myanimated;
          width: 100%;
          max-width: 160px;
          height: 100%;
          max-height: 100px;
          .object-fit(contain);
          &:hover {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
          }
        }
      }
    }
  }
}

.content {
  padding: 54px 0 0 0;
  > .container {
    padding-top: 3em;
    padding-bottom: 4em;
    @media @normal {
      padding-top: 2em;
    }
  }
  @media @normal {
    padding: 9em 0 0 0;
  }
}

.not-front {
  h1 {
    text-align: left;
    border: none;
    text-transform: none;
    font-size: @font-size-h1;
    font-weight: 500;
    @media @normal {
      display: block;
    }
  }
}
.hp_projects {
  padding: 2rem 0;
  background: @gray-base;
  .block-title {
    text-transform: uppercase;
    color: @theme-color-1;
    span {
      color: @white;
    }
  }
  .container {
    .widecontainer;
  }
  @media @normal {
    padding: 3rem 0;
  }
  @media @xwide {
    padding: 4rem 0;
  }
  .view-footer {
    text-align: center;
    text-transform: uppercase;
    font-size: @font-size-small;
  }
}
.hp_projects,
.page-projects {
  .view-projects {
    .view-content {
      .flex-container(row, wrap, center, flex-start, flex-start);
      .views-row {
        .flex-element(0, 0, 0, 50%);
        padding: 0 @grid-gutter-width / 2;
        margin: 1rem 0 2rem 0;
        img {
          width: 100%;
          height: 200px;
          .object-fit(cover);
          .myanimated;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
        &:hover {
          img {
            .scale(1.1);
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
          }
        }
      }
    }
  }
  @media @tablet {
    .view-projects {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 33.333%);
          img {
            height: 240px;
          }
        }
      }
    }
  }
  @media @normal {
    .view-projects {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 20%);
          img {
            height: 240px;
          }
        }
      }
    }
  }
  @media @wide {
    .view-projects {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 16.666666%);
          img {
            height: 240px;
          }
        }
      }
    }
  }
  @media @xwide {
    .view-projects {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 16.666666%);
          img {
            height: 360px;
          }
        }
      }
    }
  }
}
.hp_professionals {
  padding: 2rem 0;
  background-color: @gray-dark;
  background-image: url(../images/fern.svg);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    background: url(../images/overlay-pattern.png);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    visibility: visible;
    display: block;
  }
  .container {
    position: relative;
    z-index: 1;
    .widecontainer;
  }
  .view-footer {
    text-align: center;
    text-transform: uppercase;
    font-size: @font-size-small;
  }
  .block-title {
    text-transform: uppercase;
    color: @theme-color-1;
    span {
      color: @white;
    }
  }
  .view-stunt-professionals {
    .view-content {
      text-align: center;
      .slick--view--stunt-professionals {
        width: 100%;
        .slick__slide {
          cursor: pointer;
          max-width: 50%;
          padding: 0 1rem;
          display: inline-block;
          vertical-align: top;
          img {
            width: 100%;
            height: 200px;
            .object-fit(cover);
            .myanimated;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
          }
          &:hover {
            img {
              .scale(1.1);
              -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
              filter: grayscale(0);
            }
          }
          .slide__content {
            margin: 0 0 2rem 0;
            padding: 0;
            .preview {
              h3 {
                font-size: @font-size-large;
                white-space: nowrap;
              }
            }
          }
          @media @tablet {
            max-width: 25%;
          }
          @media @normal {
            max-width: 100%;
            display: block;
          }
        }
      }
    }
    .preview {
      cursor: pointer;
      .img {
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 200px;
        .object-fit(cover);
        .myanimated;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
      &:hover {
        img {
          .scale(1.1);
          -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
          filter: grayscale(0);
        }
      }
    }
    .profile-popup {
      position: absolute;
      width: 100%;
      height: auto;
      min-height: 100%;
      left: 0;
      right: 0;
      top: -2rem;
      background: @gray-dark;
      border: 1px solid @gray;
      padding: 2rem;
      z-index: 101;
      .bezieranimated;
      transition-delay: 0.1s;
      overflow: visible;
      .opacity(1);
      .scale(1);
      text-align: left;
      .closebtn {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: @font-size-h2;
        cursor: pointer;
        line-height: 1;
        .myanimated;
        &:hover {
          color: @theme-color-1;
        }
      }
      &.hidden-popup {
        overflow: hidden;
        .opacity(0);
        display: block;
        padding: 0;
        border: none;
        .scale(0);
      }
      h3 {
        margin: 0 0 1rem 0;
      }
      h4 {
        font-size: @font-size-large;
        text-transform: uppercase;
        margin-top: 1.5rem;
      }
      .details {
        display: block;
        margin-bottom: 0.25rem;
      }
      ul {
        list-style: none;
        margin: 0.5rem 0;
        padding: 0;
        li {
          margin: 0 0 0.25rem 0;
          padding: 0;
        }
      }
      .col-md-8 {
        > a {
          .btn;
          .redbtn;
          margin-top: 0.5rem;
        }
      }
      .col-md-4 {
        .flag {
          margin-top: 1rem;
          .btn;
          .bluebtn;
          width: 100%;
          text-align: center;
          position: relative;
          &:before {
            position: absolute;
            content: '\f005';
            font-family: @font-family-awesome;
            color: @theme-color-3;
            font-size: @font-size-h4;
            left: 0.5rem;
            top: 0.6rem;
            .myanimated;
          }
        }
        .downloadcv {
          position: relative;
          &:before {
            position: absolute;
            content: '\f019';
            font-family: @font-family-awesome;
            color: lighten(@theme-color-2, 12%);
            font-size: @font-size-h4;
            left: 0.5rem;
            top: 0.6rem;
            .myanimated;
          }
          &:hover {
            &:before {
              color: @theme-color-2;
            }
          }
        }
        a {
          display: block;
          text-align: center;
        }
      }
      @media @normal {
        top: -3rem;
      }
      @media @xwide {
        top: -4rem;
      }
    }
  }
  @media @tablet {
    .view-stunt-professionals {
      .preview {
        img {
          height: 180px;
        }
      }
    }
  }
  @media @normal {
    padding: 3rem 0;
    .view-stunt-professionals {
      .preview {
        img {
          height: 180px;
        }
      }
    }
  }
  @media @wide {
    .view-stunt-professionals {
      .preview {
        img {
          height: 200px;
        }
      }
      .profile-popup {
        width: 80%;
        left: 10%;
        right: 10%;
      }
    }
  }
  @media @xwide {
    padding: 4rem 0;
    .view-stunt-professionals {
      .preview {
        img {
          height: 220px;
        }
      }
      .profile-popup {
        width: 60%;
        left: 20%;
        right: 20%;
      }
    }
  }
}
.hp_gallery {
  padding: 2rem 0;
  background: @gray-base;
  .block-title {
    text-transform: uppercase;
    padding-bottom: 20px;
    color: @theme-color-1;
    span {
      color: @white;
    }
  }
  @media @normal {
    padding: 3rem 0;
  }
  @media @xwide {
    padding: 4rem 0;
  }
  .view-footer {
    text-align: center;
    margin-top: 2rem;
    text-transform: uppercase;
    font-size: @font-size-small;
  }
}
.hp_gallery,
.page-gallery {
  .view-guild-stunt-gallery {
    .slick-track,
    .view-content {
      /*.flex-container(row, wrap, center, flex-start, flex-start);*/
      .slick__slide,
      .views-row {
        /*.flex-element(0, 0, 0, 100%);*/
        padding: 1rem;
        margin: 0;
        position: relative;
        .views-field-view-node {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          a {
            font-size: 0;
            &:after {
              content: '\f0c1';
              font-family: @font-family-awesome;
              font-size: @font-size-large;
              padding: 0.5rem;
              background: rgba(255, 255, 255, 0.05);
              color: rgba(255, 255, 255, 0.2);
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
        .colorbox {
          display: block;
          position: relative;
          z-index: 0;
          &:before {
            content: '';
            position: absolute;
            z-index: -2;
            width: 100%;
            height: 100%;
            background: none;
            box-shadow: inset 0px 100px 100px -50px rgba(0, 0, 0, 0.5),
              inset 0px -100px 100px -50px rgba(0, 0, 0, 0.5);
            display: block;
            left: 0;
            top: 0;
          }
          &:after {
            content: '';
            position: absolute;
            z-index: -1;
            background: url(../images/overlay-pattern.png);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            visibility: visible;
            display: block;
          }
        }
        img {
          /*width:100%;*/
          height: 260px;
          .object-fit(cover);
          .myanimated;
          position: relative;
          z-index: -3;
          @media @normal {
            height: 320px;
          }
          @media @wide {
            height: 360px;
          }
          @media @xwide {
            height: 480px;
          }
        }
        &:hover {
          .views-field-view-node {
            a {
              &:after {
                background: rgba(255, 255, 255, 0.2);
                color: rgba(255, 255, 255, 0.4);
              }
            }
          }
        }
      }
    }
    .view-content {
      .flex-container(row, wrap, center, flex-start, flex-start);
      .views-row {
        .flex-element(0, 0, 0, 100%);
      }
    }
  }
  @media @tablet {
    .view-guild-stunt-gallery {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 50%);
          padding: 0.5rem;
          img {
            height: 200px;
          }
        }
      }
    }
  }
  @media @normal {
    .view-guild-stunt-gallery {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 50%);
          img {
            height: 260px;
          }
        }
      }
    }
  }
  @media @wide {
    .view-guild-stunt-gallery {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 33.333333%);
        }
      }
    }
  }
  @media @xwide {
    .view-guild-stunt-gallery {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 25%);
        }
      }
    }
  }
}
.slick-dots {
  bottom: -3rem;
  li {
    font-size: 0;
    margin: 0 0.2rem;
    width: 16px;
    height: 16px;
    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      .border-radius(10px);
      border: 2px solid @white;
    }
    &.slick-active {
      &:before {
        background: @white;
      }
    }
  }
}
.professionals-page {
  .view-stunt-professionals {
    .view-filters {
      background: @gray-mid-dark;
      padding: 1rem;
      margin-bottom: 2rem;
      @media @tablet {
        background-image: url(../images/logo-gray.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
      }
      .views-exposed-widgets {
        .flex-container(row, wrap, flex-start, flex-start, flex-start);
        > div {
          .flex-element(0, 0, 0, 100%);
          @media @tablet {
            .flex-element(0, 0, 0, auto);
          }
          .form-type-select {
            position: relative;
            &:after {
              color: @white;
              content: '\f078';
              font-family: @font-family-awesome;
              font-style: normal;
              font-weight: 400;
              line-height: 1;
              margin-top: -0.5em;
              padding-right: 0.5em;
              pointer-events: none;
              position: absolute;
              right: 0;
              top: 50%;
              z-index: 10;
            }
          }
          .input-group {
            display: block;
          }
          input {
            width: 100%;
            background: @gray;
            border-color: @gray;
            color: @white;
            @media @tablet {
              width: 280px;
            }
            @media @normal {
              width: 180px;
            }
            @media @wide {
              width: 300px;
            }
            @media @xwide {
              width: 360px;
            }
          }
          select {
            width: 100%;
            background: @gray;
            border-color: @gray;
            color: @white;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            @media @tablet {
              max-width: 160px;
              min-width: 160px;
            }
            @media @normal {
              max-width: 120px;
              min-width: 120px;
            }
            @media @wide {
              max-width: 140px;
              min-width: 140px;
            }
            @media @xwide {
              max-width: 180px;
              min-width: 180px;
            }
          }
          label {
            font-size: @font-size-small;
            color: @gray-light;
            font-weight: 400;
          }
          .input-group-addon {
            display: none;
          }
          .btn {
            .redbtn;
            .bigbtn;
            padding: 0.72rem 2rem;
            margin-top: 1.9rem;
            .border-radius(3px);
          }
        }
      }
    }
    .view-content {
      .flex-container(row, wrap, center, flex-start, flex-start);
      text-align: center;
      > h3 {
        .flex-element(0, 0, 0, 100%);
        margin-top: 2rem;
        text-transform: none;
        .profile-type-container {
          text-align: left;
          .profile-type-description {
            font-size: @font-size-base;
            font-weight: 300;
            margin-top: 1rem;
            color: @white;
            line-height: 1.8;
          }
          .profile-type-title {
            text-transform: uppercase;
            text-align: center;
            font-size: @font-size-h4;
            font-weight: 600;
            color: @theme-color-1;
          }
        }
      }
      .views-row {
        .flex-element(0, 0, 0, 50%);
        padding: 0 @grid-gutter-width / 2;
        margin: 1rem 0 2rem 0;
        float: left;
        width: 50%;
        .preview {
          cursor: pointer;
          h3 {
            font-size: @font-size-small;
            text-transform: uppercase;
            a {
              color: @theme-color-3;
            }
          }
          img {
            width: 100%;
            height: 190px;
            .object-fit(cover);
            .myanimated;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            margin: 0;
          }
          &:hover {
            img {
              .scale(1.1);
              -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
              filter: grayscale(0);
            }
          }
        }
      }
    }
  }
  @media @tablet {
    .view-stunt-professionals {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 25%);
          float: none;
          width: auto;
          .preview {
            img {
              height: 210px;
            }
          }
        }
      }
    }
  }
  @media @normal {
    padding: 3rem 0;
    .view-stunt-professionals {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 20%);
          .preview {
            img {
              height: 220px;
            }
          }
        }
      }
    }
  }
  @media @wide {
    .view-stunt-professionals {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 16.666666%);
          .preview {
            img {
              height: 230px;
            }
          }
        }
      }
    }
  }
  @media @xwide {
    padding: 4rem 0;
    .view-stunt-professionals {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 16.666666%);
          .preview {
            img {
              height: 280px;
            }
          }
        }
      }
    }
  }
}
.breadcrumbs {
  font-weight: 500;
  margin-top: -2rem;
  margin-bottom: 2rem;
  font-size: @font-size-small;
  text-transform: uppercase;
  .breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
  }
  a {
    color: @theme-color-1;
  }
  @media @wide {
    margin-bottom: 2rem;
  }
}

.slick-arrow {
  &.slick-prev,
  &.slick-next {
    &:before {
      font-family: @font-family-awesome;
      font-size: @font-size-h1;
      color: @white;
      font-weight: normal;
    }
  }
  &.slick-prev {
    &:before {
      content: '\f053';
    }
  }
  &.slick-next {
    &:before {
      content: '\f054';
    }
  }
}
.slick-wrapper {
  margin-bottom: 4rem;
}
.slick--display--thumbnail {
  margin-top: 1rem;
  .slick-track {
    transform: none !important;
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    text-align: left;
  }
  .slick-slide {
    position: relative;
    border-right: 1rem solid rgba(0, 0, 0, 0);
    overflow: hidden;
    box-sizing: border-box;
    width: 25% !important;
    &:before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      box-sizing: border-box;
    }
    img {
      .object-fit(cover);
      height: auto;
      width: 100%;
      .myanimated;
      cursor: pointer;
      &:hover {
        .scale(1.05);
      }
      @media @tablet {
        height: 100px;
      }
      @media @normal {
        height: 140px;
      }
      @media @wide {
        height: 120px;
      }
      @media @xwide {
        height: 140px;
      }
    }
    &.slick-current {
      &:before {
        content: '';
        background: rgba(255, 255, 255, 0.45);
      }
    }
    &.slick-cloned {
      display: none;
    }
  }
  .slick__arrow {
    display: none;
  }
}
.slick--main {
  .slick-track {
    .slick-slide {
      img {
        width: 100%;
        height: 220px;
        .object-fit(cover);
        @media @tablet {
          height: 360px;
        }
        @media @normal {
          height: 360px;
        }
        @media @wide {
          height: 420px;
        }
        @media @xwide {
          height: 500px;
        }
      }
    }
  }
}
#block-views-my-favorites-block {
  .view-my-favorites {
    .view-content {
      .flex-container(row, wrap, center, flex-start, flex-start);
      text-align: center;
      .views-row {
        .flex-element(0, 0, 0, 100%);
        padding: 0 @grid-gutter-width / 2;
        margin: 1rem 0 2rem 0;
        .field-name-title {
          cursor: pointer;
          a {
            color: @theme-color-3;
          }
        }
        .field-name-field-image {
          img {
            width: 100%;
            height: 160px;
            .object-fit(cover);
            .myanimated;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
          }
        }
        .flag {
          display: block;
          .btn;
          .bluebtn;
          font-size: @font-size-small;
        }
        &:hover {
          .field-name-field-image {
            img {
              .scale(1.1);
              -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
              filter: grayscale(0);
            }
          }
        }
      }
    }
  }
  @media @tablet {
    .view-my-favorites {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 33.333%);
          .field-name-field-image {
            img {
              height: 180px;
            }
          }
        }
      }
    }
  }
  @media @normal {
    padding: 3rem 0;
    .view-my-favorites {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 20%);
          .field-name-field-image {
            img {
              height: 180px;
            }
          }
        }
      }
    }
  }
  @media @wide {
    .view-my-favorites {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 16.666666%);
          .field-name-field-image {
            img {
              height: 200px;
            }
          }
        }
      }
    }
  }
  @media @xwide {
    padding: 4rem 0;
    .view-my-favorites {
      .view-content {
        .views-row {
          .flex-element(0, 0, 0, 12.5%);
          .field-name-field-image {
            img {
              height: 220px;
            }
          }
        }
      }
    }
  }
}
.node-type-profile,
.view-my-profile {
  .group-personal {
    padding: 0;
    .field-name-field-email,
    .field-name-profile-email-link,
    .field-name-field-cell,
    .field-name-profile-contact-link,
    .field-name-field-location,
    .field-name-field-web,
    .field-name-profile-web-link {
      border-left: 5px solid @theme-color-1;
      margin: 0;
      padding: 0.35rem 0 0.35rem 1rem;
      a {
        color: @theme-color-3;
      }
      .field-label {
        font-weight: 400;
        display: inline-block;
        width: 75px;
      }
    }
    .slick {
      margin-bottom: 2rem;
    }
    .slick-media {
      img {
        .object-fit(contain);
        width: 100%;
        height: 360px;
        @media @tablet {
          height: 440px;
        }
        @media @tablet {
          height: 580px;
        }
        @media @normal {
          height: 540px;
        }
        @media @wide {
          height: 600px;
        }
        @media @xwide {
          height: 640px;
        }
      }
    }
    .group-btns {
      margin-top: 2rem;
      .clearfix;
      > .field,
      .flag-outer {
        margin: 0 0 0.5rem 0;
        width: 100%;
        display: block;
        .flag-throbber {
          display: none;
        }
        a,
        button {
          .btn;
          .bluebtn;
          padding: 0.75rem;
          display: block;
          font-size: @font-size-small;
          position: relative;
          &:before {
            position: absolute;
            font-family: @font-family-awesome;
            color: lighten(@theme-color-2, 12%);
            font-size: @font-size-h4;
            left: 0.5rem;
            top: 0.75rem;
            .myanimated;
          }
          &:hover {
            &:before {
              color: @theme-color-2;
            }
          }
          &:focus {
            color: @white;
            outline: none;
          }
        }
        @media @normal {
          width: 47%;
          float: left;
          margin-right: 3%;
        }
      }
      .field-name-profile-show-reel-link {
        a {
          &:before {
            content: '\f03d';
          }
        }
      }
      .field-name-print-button {
        button {
          margin: 0 !important;
          width: 100%;
          &:before {
            content: '\f02f';
          }
        }
      }
      .field-name-cv-download-link {
        a {
          &:before {
            content: '\f019';
          }
        }
      }
      .field-name-imdb-link {
        a {
          &:before {
            content: '\f0ac';
          }
        }
      }
      .flag-outer {
        a {
          &:before {
            content: '\f005';
            color: @theme-color-3;
          }
          &:hover {
            &:before {
              color: @theme-color-3;
            }
          }
        }
      }
    }
  }
  .group-descr {
    padding: 0;
    margin-top: 2rem;
    @media @tablet {
      padding: 0 0 0 2rem;
      margin: 0;
    }
    @media @wide {
      padding: 0 0 0 4rem;
    }
    @media @xwide {
      padding: 0 0 0 6rem;
    }
    .field-name-body {
      margin-bottom: 2rem;
    }
    .group-casting-details {
      margin-bottom: 2rem;
      h3 {
        font-size: @font-size-h4;
        text-transform: uppercase;
        font-weight: 600;
      }
      .field {
        margin-bottom: 0.5rem;
        .field-label {
          font-weight: 400;
          display: inline-block;
          width: 120px;
        }
      }
    }
    > .field {
      margin-bottom: 2rem;
      .field-label {
        font-size: @font-size-h4;
        text-transform: uppercase;
        font-weight: 500;
        color: @theme-color-1;
      }
    }
    .field-collection-container {
      margin-bottom: 2rem;
      border: none;
      .field-name-field-profile-recent-credits {
        > .field-label {
          font-size: @font-size-h4;
          text-transform: uppercase;
          font-weight: 500;
          color: @theme-color-1;
        }
        .field-collection-view {
          margin: 0;
          padding: 0;
          border: none;
          .content {
            padding: 0;
            .clearfix;
            > .field {
              display: inline-block;
              margin-right: 0.5rem;
              .field-label {
                display: none;
              }
            }
          }
        }
        .field-collection-view-links {
          display: none;
        }
      }
      .action-links {
        display: none;
      }
    }
  }
}
blockquote {
  padding: 9.5pt 19pt;
  margin: 15pt 0 19pt 15pt;
  font-size: 12pt;
  border-left: 10px solid #cb2a2a;
}
.region-content {
  h3 {
    font-weight: 500;
    margin: 2em 0 1em 0;
    text-transform: capitalize;
  }
}
.info-sidebar {
  border-left: 5px solid #cb2a2a;
  li {
    list-style: none;
    font-size: 11pt;
    margin: 4px 0;
  }
  p {
    padding-left: 15px;
  }
}
#block-block-7,
#block-block-8,
#block-block-9,
#block-block-10,
#block-block-11 {
  a {
    color: #cb2a2a;
  }
}

.page-node-28 {
  h3 {
    font-weight: 500;
    margin: 2em 0 1em 0;
    text-transform: capitalize;
    border-left: 5px solid #cb2a2a;
    padding-left: 10px;
  }
}

.webform-client-form {
  .btn-primary {
    background-color: #cb2a2a;
  }
  .form-control {
    color: #ffffff;
    background-color: #898989;
    border: none;
  }
}
.partners {
  img {
    opacity: 0.3;
  }
}

.slick-dots li:before {
  border: 2px solid #8c8c8c;
}
.slick-dots li.slick-active:before {
  background: #8c8c8c;
}

.node-news,
.node-training-course,
.node-job-listings {
  .field-type-image {
    float: right;
    margin: 0 20px 20px 20px;
  }

  .field-name-field-date {
    font-size: 13pt;
    padding: 10px;
    background: #373737;
    border-top: 1px solid #484848;
    border-bottom: 1px solid #484848;
    margin-bottom: 30px;
  }
  .field-name-body {
    margin-bottom: 30px;
  }
}
.node-teaser {
  .field-type-image {
    float: left;
    margin: 0 30px 20px 0px;
  }
  .field-name-field-date {
    font-size: 13pt;
    padding: 0;
    background: none;
    border: none;
    margin-bottom: 10px;
  }
}

/* Profile Form Styles */

.node-profile {
  .field-name-edit-my-profile {
    margin-bottom: 15px;
    a {
      color: #ffba00 !important;
      text-align: right;
    }
  }
}

.node-type-profile {
  .vertical-tabs {
    margin-top: 30px;
  }
  #edit-actions {
    text-align: center;
  }
  button {
    background-color: #cb2a2a;
  }
  .btn {
    margin-left: 10px !important;
  }
  .popover {
    h3 {
      color: #333;
    }
  }
}

.node-profile-form {
  .tab-content {
    background-color: #222;
    border: none;
    border-top: 1px solid #4c4c4c;
    border-left: 1px solid #4c4c4c;
  }
  .btn-primary {
    background-color: #cb2a2a;
  }
  .btn-danger {
    background-color: #cb2a2a;
  }
  .form-control {
    color: #ffffff;
    background-color: #898989;
    border: none;
  }
  .field-name-field-image {
    .panel-body {
      color: #333;
    }
  }

  .field-name-field-show-reel {
    .panel-body {
      color: #333;
    }
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: #ffba00;
    background-color: #222222;
    border: 1px solid #4c4c4c;
    border-right: none;
    cursor: default;
  }
  .tabs-left > .nav-tabs > li > a:hover {
    color: #ffba00;
    background-color: #222222;
    border: 1px solid #4c4c4c;
    border-right: none;
    cursor: default;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #333;
  }
  .table > tbody > tr > td {
    border-top: 1px solid #333;
    background-color: #333;
  }
}

#user-login {
  .btn-primary {
    background-color: @theme-color-1;
  }
  .form-control {
    color: #ffffff;
    background-color: #898989;
    border: none;
  }
}

.page-node-edit.node-type-profile {
  .tabledrag-toggle-weight-wrapper {
    margin-bottom: 1rem;
    a {
      display: none;
    }
  }
}
.node-type-gallery {
  .node-gallery {
  }
}
.field-multiple-table {
  .draggable {
    td {
      &:nth-child(2) {
        .flex-container(row, wrap, flex-start, flex-start, flex-start);
        .ajax-new-content {
          .flex-container(row, wrap, flex-start, flex-start, flex-start);
        }
        .form-wrapper {
          .flex-element(0, 1, 1, 100%);
          @media @tablet {
            .flex-element(0, 1, 1, 48%);
            margin-right: 2%;
          }
          @media @wide {
            .flex-element(0, 1, 1, 23%);
            margin-right: 2%;
          }
          @media @xwide {
            .flex-element(0, 1, 1, 19.5%);
            margin-right: 2%;
          }
          .control-label {
            font-size: @font-size-base;
            font-weight: 400;
          }
        }
        .btn {
          margin: 0.5rem 0 0 0 !important;
          font-size: @font-size-small;
          background: @gray;
          @media @xwide {
            margin: 2rem 0 0 0 !important;
          }
        }
      }
    }
  }
}
.page-user-password {
  #user-pass {
    #edit-submit {
      background-color: @theme-color-1;
    }
  }
}
.page-user-register {
  #user-register-form {
    #edit-submit {
      background-color: @theme-color-1;
    }
  }
}

@media print {
  body {
    padding: 2em !important;
    margin: 0 !important;
  }
  .container {
    width: 100% !important;
  }
  * {
    font-size: @font-size-base !important;
    color: @gray-base !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.4 !important;
    border: none !important;
    &:before {
      display: none !important;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 1em 0 0.5em 0 !important;
    display: block !important;
  }
  h1 {
    font-size: @font-size-h1 !important;
    padding: 0 0 1em 0 !important;
  }
  h2 {
    font-size: @font-size-h2 !important;
  }
  h3 {
    font-size: @font-size-h3 !important;
  }
  h4 {
    font-size: @font-size-h4 !important;
  }
  h5 {
    font-size: @font-size-h5 !important;
  }
  a[href]:after {
    content: none !important;
  }
  .header,
  .mobile_header,
  .footer,
  .breadcrumbs,
  .nav-tabs,
  .group-btns,
  .alert {
    display: none !important;
  }

  .node-profile.view-mode-full {
    display: block;
    width: 100%;
    .group-descr {
      > div {
        margin-bottom: 1rem !important;
      }
    }

    .group-personal {
      width: 50%;

      .slick-wrapper {
        margin-bottom: 0 !important;
        > .slick {
          margin: 0 !important;
          .slick-track {
            height: 550px;
            img {
              width: 400px !important;
              height: auto !important;
              max-height: auto !important;
              max-width: 100% !important;
            }
          }
        }
      }

      .slick--display--thumbnail {
        .slick-track {
          display: none !important;
        }
      }
      .slick--display--main {
        margin-bottom: 2rem !important;
        // .slick-active {
        //   img {
        //     height: auto;
        //     max-height: 400px !important;
        //   }
        // }
      }
    }

    .group-descr {
      width: auto;
    }
  }
}
.page-members-guild-files {
  .view-news {
    .views-table {
      background: none;
      * {
        background: none;
      }
    }
  }
}
i.media-icon {
  display: none;
}

body {
  .chosen-container-multi {
    .chosen-choices {
      padding: 15px !important;
      z-index: 10 !important;
    }
  }

  .node-profile-form {
    .form-item-field-capabilities-und {
      .chosen-container-multi {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
